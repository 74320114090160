.mediaCol {
  border: 1px solid #0c5a62;
  border-radius: 5px;
  margin-bottom: 5px;

  .downloadLink {
    color: #0c5a62;

    &:hover {
      text-decoration: none !important;
    }

    i {
      font-size: 1.25rem;
      margin-left: 15px;
    }
  }

  .mediaContainer {
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 10px;

    .dataContainer {
      display: flex;
      justify-content: space-around;

      width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .mobileColumn {
    flex-direction: column;
  }

  .mobileColumn span b {
    display: block;
    margin-bottom: 5px;
  }

  .downloadLinks {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 15px 0;

    .downloadLink i {
      font-size: 2rem;
    }
  }
}
