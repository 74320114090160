.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tableBody {
  width: 100%;
  background-color: white;
  padding-right: 32px;
  padding-left: 32px;
  border-radius: 3px 3px 0 0;
  display: flex;
  flex-direction: column;
  height: calc(100% - 46px);
}

.PageTitle {
  color: #000000;
  font-size: 15px;
  letter-spacing: 0.21px;
  line-height: 18px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  background-color: rgb(234, 239, 247);
  padding-right: 32px;
  padding-left: 32px;
  justify-content: space-between;
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 35px;
  margin-top: 17px;
  margin-bottom: 17px;
}

.paginationButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  line-height: 35px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 8px;
  margin-right: 8px;
  border: 0.85px solid #c8ced3;
  border-radius: 3.4px;
  cursor: pointer;
}

.paginationButton[disabled] {
  pointer-events: none;
  opacity: 0.7;
  cursor: default;
}

.paginationInnerButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 35px;
  line-height: 35px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 2px;
  margin-right: 2px;
  border: 0.85px solid #c8ced3;
  border-radius: 3.4px;
  background-color: white;
}

.paginationInnerButtonActive {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  line-height: 35px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 2px;
  margin-right: 2px;
  border: 0.85px solid #c8ced3;
  border-radius: 3.4px;
  background-color: rgb(16, 121, 132);;
}
