.fragmentContainer {
  display: inline;
  width: 100%;

  .pageTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pageTitle {
      color: #000000;
      font-size: 15px;
      letter-spacing: 0.21px;
      line-height: 15px;
      background-color: rgb(234, 239, 247);
      padding: 15px 32px;
      justify-content: space-between;
    }

    .pageTitleButton {
      margin-right: 32px;
    }
  }

  .colContainer {
    background: white;
    width: 100%;

    .tabContainer {
      padding: 30px;

      .navContainer {
        background: rgb(229, 235, 245);
        border-radius: 0.25em 0.25em 0 0;
      }
    }

    .tabContent {
      border-radius: 0 0 0.25em 0.25em;
    }
  }
}
