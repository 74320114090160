// If you want to override variables do it here
@import 'variables';

// Import styles with default layout.
// If you are going to use dark layout please comment next line

@import '~@coreui/coreui-pro/scss/variables';

$theme-colors: map-remove($theme-colors, 'primary');
$primary: rgb(16, 121, 132);
$secondary: rgb(12, 90, 98);
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
  ),
  $theme-colors
);

// Import Bootstrap source files
@import '~@coreui/coreui-pro/scss/bootstrap/bootstrap'; // from bootstrap node_modules

// Mixins
@import '~@coreui/coreui-pro/scss/mixins';

// Animations
@import '~@coreui/coreui-pro/scss/animate';

// Vendors
@import '~@coreui/coreui-pro/scss/vendors';

// Components
@import '~@coreui/coreui-pro/scss/aside';
@import '~@coreui/coreui-pro/scss/avatars';
@import '~@coreui/coreui-pro/scss/badge';
@import '~@coreui/coreui-pro/scss/breadcrumb-menu';
@import '~@coreui/coreui-pro/scss/breadcrumb';
@import '~@coreui/coreui-pro/scss/brand-card';
@import '~@coreui/coreui-pro/scss/brand-buttons';
@import '~@coreui/coreui-pro/scss/button-group';
@import '~@coreui/coreui-pro/scss/buttons';
@import '~@coreui/coreui-pro/scss/callout';
@import '~@coreui/coreui-pro/scss/card';
@import '~@coreui/coreui-pro/scss/charts';
@import '~@coreui/coreui-pro/scss/dropdown';
@import '~@coreui/coreui-pro/scss/footer';
@import '~@coreui/coreui-pro/scss/grid';
@import '~@coreui/coreui-pro/scss/header';
@import '~@coreui/coreui-pro/scss/input-group';
@import '~@coreui/coreui-pro/scss/images';
@import '~@coreui/coreui-pro/scss/list-group';
@import '~@coreui/coreui-pro/scss/modal';
@import '~@coreui/coreui-pro/scss/nav';
@import '~@coreui/coreui-pro/scss/progress';
@import '~@coreui/coreui-pro/scss/progress-group';
@import '~@coreui/coreui-pro/scss/sidebar';
@import '~@coreui/coreui-pro/scss/switches';
@import '~@coreui/coreui-pro/scss/tables';
@import '~@coreui/coreui-pro/scss/widgets';

// Layout Options
@import '~@coreui/coreui-pro/scss/layout';

@import '~@coreui/coreui-pro/scss/others';

// Utility classes
@import '~@coreui/coreui-pro/scss/utilities';

// Apps
@import '~@coreui/coreui-pro/scss/apps';

// Right-to-left
@import '~@coreui/coreui-pro/scss/rtl';

// Custom Properties support for Internet Explorer
@import '~@coreui/coreui-pro/scss/ie-custom-properties';

// temp fixes
@import '~@coreui/coreui-pro/scss/fixes';

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui-pro/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import 'custom';

// ie fixes
@import 'ie-fix';

// temp fixes
@import 'fixes';

// Spinkit
$spinkit-spinner-color: $body-color;
@import '~spinkit/scss/spinkit.scss';

@import 'assets/fonts/Montserrat.css';

body,
html {
  font-family: 'Montserrat';
  font-weight: lighter;
}

.sidebar .nav-link.active,
.sidebar .nav-link:hover {
  color: #ffffff;
  background-color: $secondary;
}

.sidebar .nav-link .nav-icon {
  color: #f3f3f3;
}

.sidebar .nav-link.active .nav-icon {
  color: #ffffff;
}
::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(200, 200, 200, 0.7);
}

.react-datepicker {
  display: flex !important;
}

.react-datepicker__input-container {
  display: block;
  width: 100%;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.navbar-brand {
  @media screen and (max-width: 900px) {
    img {
      display: none;
    }
  }
  @media screen and (min-width: 900px) {
    img {
      object-fit: contain;
      padding: 10px;
    }
  }
}

@media (max-width: 992px) {
  .tab-content .tab-pane {
    padding: 1rem 0.25rem;
  }

  nav ol.breadcrumb {
    border-bottom: none;
  }
}
