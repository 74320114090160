.rowContainer {
  height: 70vh;

  .elementsContainer {
    margin: 10px 15px;
    max-height: 90%;
    overflow: scroll;

    .noRelated {
      display: inline;
      text-align: center;
      width: 100%;
    }
  }
}
