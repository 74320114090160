.chartContainer {
  height: 200px;
  margin-top: 20px;
}
.legendContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 992px) {
  .legendContainer {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.legendItem {
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.legendColor {
  height: 12px;
  width: 42px;
  margin-right: 5px;
}

.legendText {
  text-align: center;
  font-size: 12px;
  font-family: Arial;
  color: #666;
}
