.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.logo {
  width: 40%;
  margin-bottom: 40px;
}
.sublogo {
  width: 200px;
}
.subtitle {
  font-size: 16px;
  font-weight: bold;
}
