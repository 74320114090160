.avatarContainer {
  position: relative;
  text-align: center;

  .avatarImg {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
    border: 1px solid rgb(16, 121, 132);
    object-fit: cover;
  }

  .avatarBottom {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    overflow: hidden;

    .avatarText {
      background-color: rgb(16, 121, 132);
      border: 1px solid rgb(35, 61, 109);
      border-radius: 7px;
      color: white;
      font-size: 14px;
      padding: 10px 20px;
      cursor: pointer !important;

      .inputFile {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  &.editable {
    .avatarImg {
      width: 150px !important;
      height: 150px !important;
    }
    &:hover,
    &.loader {
      cursor: pointer;

      .avatarImg {
        opacity: 0.6;
      }
      .avatarBottom {
        opacity: 1;
      }
    }
  }

  &.xl .avatarImg {
    width: 150px !important;
    height: 150px !important;
  }
}
