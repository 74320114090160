.colContainer {
  border: 1px solid rgb(40, 71, 129);
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;

  .avatarContainer {
    text-align: center;

    .avatar {
      border-radius: 50%;
      object-fit: cover;
      width: 100px;
      height: 100px;
      margin: 8px;
      border: 1px solid rgb(40, 71, 129);
    }
  }

  .audioDataDb {
    margin-top: 20px;
  }

  .audioDataDbA {
    margin-top: 20px;
  }

  .userData {
    max-width: 50%;
  }

  .userData p {
    margin-bottom: 1px;
    word-break: break-word;
  }
}
