/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/


/** Montserrat Light **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: normal;
    src: url("Montserrat-Light.otf") format('opentype');
}


/** Montserrat Regular **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("Montserrat-Regular.otf") format('opentype');
}



/** Montserrat SemiBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: normal;
    src: url("Montserrat-SemiBold.otf") format('opentype');
}



/** Montserrat Bold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    src: url("Montserrat-Bold.otf") format('opentype');
}



/** Montserrat ExtraBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: normal;
    src: url("Montserrat-ExtraBold.otf") format('opentype');
}


/** Montserrat Black **/
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: normal;
    src: url("Montserrat-Black.otf") format('opentype');
}