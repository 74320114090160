.thumbnailContainer {
  position: relative;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;

  .thumbnailImg {
    vertical-align: middle;
    width: 100%;
    height: 150px;
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
    border: 1px solid rgb(16, 121, 132);
    border-radius: 7px;
    object-fit: cover;
  }

  .thumbnailBottom {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    width: 70%;

    .thumbnailText {
      background-color: rgb(16, 121, 132);
      border: 1px solid rgb(35, 61, 109);
      border-radius: 7px;
      color: white;
      font-size: 14px;
      padding: 10px 20px;
      cursor: pointer !important;
      display: flex;
      align-items: center;

      .thumbnailA {
        padding: 0;

        &:hover {
          background-color: #4272b8 !important;
          border-color: #4272b8 !important;
        }
      }

      .inputFile {
        position: absolute;
        cursor: pointer !important;
        top: 0;
        bottom: 0;
        left: 20px;
        right: 0;
        width: 30%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  &.editable {
    &:hover,
    &.loader {
      .thumbnailImg {
        opacity: 0.6;
      }
      .thumbnailBottom {
        opacity: 1;
      }
    }
  }
}
