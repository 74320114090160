.labelCotainer {
  display: flex;
}

.detailLabel {
  font-size: 13px;
  align-self: center;
}

.dateInput {
  height: 100%;
}

.react-datepicker__input-container {
  display: block;
  width: 100%;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
}
