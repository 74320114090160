.audiospookContainer {
  background-color: white;
  width: 100%;
  padding: 0px 30px;
  border-radius: 5px;
  margin: 0 0 35px 0;
}

.modalRecord {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: -15px;
}

.historicContainer {
  margin-bottom: 1rem;
}

.mainDba {
  font-size: 60px;
  font-weight: bold;
}

.dbaLabel {
  background: rgb(23, 110, 117);
  color: white;
  padding: 5px 12px;
  border-radius: 5px;
  margin-left: 10px;
}

.chartContainer {
  min-height: 300px !important;
  margin-top: 30px;
  width: 100%;
  padding: 0 30px;
  overflow-x: scroll;
}

.scrollableChart {
  min-width: 800px;
  padding-right: 20px;
}

.gridValue {
  color: white;
  padding: 5px 12px;
  border-radius: 5px;
  max-width: 200px;
  margin: 10px auto;
  text-align: center;
}

:global(#LAeq) {
  background: rgb(0, 139, 150);
}
:global(#LApk) {
  background: rgb(100, 190, 200);
  color: black;
}
:global(#LAmx) {
  background: rgb(142, 207, 215);
  color: black;
}
:global(#LAmn) {
  background: rgb(203, 233, 237);
  color: black;
}
:global(#LCeq) {
  background: rgb(0, 139, 150);
}
:global(#LCpk) {
  background: rgb(100, 190, 200);
  color: black;
}
:global(#LCmx) {
  background: rgb(142, 207, 215);
  color: black;
}
:global(#LCmn) {
  background: rgb(203, 233, 237);
  color: black;
}
:global(#LZeq) {
  background: rgb(0, 139, 150);
}
:global(#LZpk) {
  background: rgb(100, 190, 200);
  color: black;
}
:global(#LZmx) {
  background: rgb(142, 207, 215);
  color: black;
}
:global(#LZmn) {
  background: rgb(203, 233, 237);
  color: black;
}

@media (max-width: 992px) {
  .audiospookContainer {
    padding: 0px 10px;
  }
}
