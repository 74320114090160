.mainDba {
  font-size: 30px;
  font-weight: bold;
}

.mainDbaLarge {
  font-size: 60px;
  font-weight: bold;
}

.dbaLabel {
  font-size: 11px;
  background: rgb(23, 110, 117);
  color: white;
  padding: 1px 7px;
  border-radius: 5px;
  margin-left: 5px;
}
